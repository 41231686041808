import React from "react"
import tw, { styled, css } from "twin.macro"
import {StaticImage} from "gatsby-plugin-image";

export default function Home() {
    return (
        <section tw="flex flex-col justify-start items-center h-screen max-w-xl mx-auto bg-bg">
            <h1 tw="text-center font-light mt-20 mb-10 text-5xl pb-2 border-b border-black"><span tw="">About</span></h1>
            <p tw="text-lg w-full">
                Hi, I'm Oscar. Welcome to my site.
            </p>
            <p tw="text-base">
                I'm a full-stack developer. I love to build and assemble things living on the internet, along the way, observing how ideas come alive.
                My journey as a software developer started in 2019.
                Since then, I've done an internship for an outsourcing company and worked in the fintech sector.
            </p>
            <p>
                Currently, im trying to get my feet wet as a freelancer.
                Here are a few technologies I’ve been working with recently:
            </p>
            <div tw="grid grid-cols-3 gap-4 mt-10 text-center">
                <ul tw="list-disc list-inside">
                    <li tw="text-left ml-5">React.js</li>
                    <li tw="text-left ml-5">Gatsby.js</li>
                    <li tw="text-left ml-5">Spring Boot</li>
                </ul>
                <ul tw="list-disc list-inside">
                    <li tw="text-left ml-5">JavaScript</li>
                    <li tw="text-left ml-5">Java</li>
                    <li tw="text-left ml-5">Groovy</li>
                    <li tw="text-left ml-5">Postgres/MySql</li>
                </ul>
                <ul tw="list-disc list-inside">
                    <li tw="text-left ml-5">Docker</li>
                    <li tw="text-left ml-5">Apache NiFi</li>
                    <li tw="text-left ml-5">Gitlab CI/CD</li>
                    <li tw="text-left ml-5">Ansible</li>
                </ul>
            </div>
            <div tw="mt-10">
                <p>I'm also:</p>
                <ul tw="list-disc">
                    <li tw="text-left ml-5">Avid coffee enjoyer</li>
                    <li tw="text-left ml-5">Gamer. Often, when I'm not working. I'm tanking my MMR in dota2</li>
                    <li tw="text-left ml-5">I'm also gradually building myself to run long distances, hope to run my first marathon soon</li>
                </ul>
            </div>
            <StaticImage
                src="../../images/coffee.png"
                alt="Laptop"
                layout="fixed"
                objectFit={"contain"}
                css={css(tw`w-[20%] h-auto`)}
            />
        </section>
    )
}