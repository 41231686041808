import {css} from 'twin.macro';
import fonts from './fonts'

const baseStyles = css`
    ${fonts}
    html {
        box-sizing: border-box;
        width: 100%;
        scroll-behavior: smooth;
    }
    body {
        margin: 0;
        width: 100%;
        min-height: 100%;
        font-family: 'Crimson Pro', serif;
    }
    h1 {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
    }
    h2, h3, p {
        font-family: 'Crimson Pro', serif;
        font-weight: 400;
    }
    header {
        font-family: 'Crimson Pro', serif;
        font-weight: 400;
    }
    // round logo
    #logo {
        font-family: 'Crimson Pro', serif;
        font-weight: 700;
        font-size: 2rem;
        color: #000;
        background-color: #fff;
        border: 4px solid #000;
        border-radius: 50%;
        padding: 0.5rem;
    }
`

export default baseStyles