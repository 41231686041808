import React, {useEffect} from "react";

const useOutsideClick = (ref, callBack) => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }

            callBack(event);
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callBack]);
}

export default useOutsideClick;