import React from "react";
import tw, {GlobalStyles, styled} from "twin.macro";
import {Global} from "@emotion/react";
import baseStyles from "../../styles/baseStyles";
import Header from "./Header";

export default function Layout({children}) {
    return (
        <div tw="bg-bg min-h-screen grid grid-cols-1 auto-cols-max auto-rows-max">
            <GlobalStyles/>
            <Global styles={baseStyles}/>
            <div tw="min-h-screen flex flex-col">
                <Header/>
                {children}
            </div>
        </div>
    )
}
