import React, {useState} from 'react';
import tw, {css} from 'twin.macro';
import {Transition} from '@headlessui/react'
import {StaticImage} from "gatsby-plugin-image";
import GitlabIcon from "../../images/icons/gitlab-logo.svg";
import MailIcon from "../../images/icons/mail-logo.svg";
import LinkedinLogo from "../../images/icons/linkedin-logo.svg";
import {ClassNames} from '@emotion/react'

export default function Hero() {
    const [isVisible, setIsVisible] = useState(true)
    const toggleTrans = () => {
        setIsVisible(!isVisible)
    }

    const SideContacts = () => {
        return (
            <div tw="
                flex justify-center items-center w-full mb-10"
                //md:flex md:flex-col md:items-center md:justify-center md:h-full md:right-14 md:left-auto md:fixed md:w-auto
            >
                <a href="https://gitlab.com/alexanderjamesking" target="_blank" rel="noreferrer">
                    <GitlabIcon tw="text-black h-8 w-8 mx-3"/>
                </a>
                <a href="https://www.linkedin.com/in/myProfile/" target="_blank" rel="noreferrer">
                    <LinkedinLogo tw="text-black h-8 w-8 mx-3"/>
                </a>
                <a href="mailto: me@gmail.com " target="_blank" rel="noreferrer">
                    <MailIcon tw="text-black h-8 w-8 mx-3"/>
                </a>
            </div>
        )
    }

    return (
        <section
            tw="flex flex-col items-center justify-center h-screen w-full bg-bg">
            {/*<div tw="flex flex-col items-center justify-center h-full w-full bg-red-200">*/}
            {/*Replace with Call to action component*/}
            <ClassNames>
                {({css}) => (<Transition
                    show={isVisible}
                    enter={css(tw`transition-transform duration-1000`)}
                    enterFrom={css(tw`opacity-0`)}
                    enterTo={css(tw`opacity-100`)}
                    leave={css(tw`transition-transform duration-300`)}
                    leaveFrom={css(tw`opacity-100`)}
                    leaveTo={css(tw`opacity-0`)}
                    tw="flex flex-col items-center justify-center h-full w-full mx-auto"
                >
                    <div tw="my-20">
                        <h1 tw="text-center font-light mt-20 text-5xl">OSCAR GIZATULIN</h1>
                        <h3 tw="text-center font-normal text-3xl">Full-Stack developer to bring your ideas to live.</h3>
                    </div>
                    <StaticImage
                        src="../../images/laptop.png"
                        alt="Laptop"
                        layout="fixed"
                        objectFit={"contain"}
                        css={css(tw`w-[30%] h-auto ml-14 mb-10`)}
                    />
                    <SideContacts/>
                </Transition>)}
            </ClassNames>
            {/*</div>*/}
        </section>
    )
}

