import React, {useEffect, useState} from "react"
import tw from "twin.macro"
import useOutsideClick from "../../hooks/useOutsideClick";
import MenuIcon from "../../images/icons/menu.svg"
import XMarkIcon from "../../images/icons/x-mark.svg"

const navLinks = [
    {
        name: "Home",
        href: "/"
    },
    {
        name: "About",
        href: "/#about"
    },
    {
        name: "Contact",
        href: "/#contact"
    },
]

export default function Menu() {
    const [open, setIsOpen] = useState(false)
    const wrapperRef = React.useRef(null)

    const handleClick = () => {
        setIsOpen(!open)
    }

    useOutsideClick(wrapperRef, () => setIsOpen(false))

    useEffect(() => {
            const body = document.querySelector('body')
            open ? body.style.overflow = 'hidden' : body.style.overflow = 'auto'
        }, [open])


    return (
        <menu ref={wrapperRef}
              tw="flex items-center justify-center bg-bg p-0 m-0 mr-7 md:hidden"
        >
            <button onClick={handleClick}>
                <MenuIcon tw="text-black h-10 w-10"/>
            </button>
            <aside
                css={[
                    open ? tw`block` : tw`hidden`,
                    tw`fixed top-0 right-0 w-1/3 h-screen bg-white z-50`
                ]}
            >
                <button onClick={handleClick}>
                    <XMarkIcon tw="text-black h-10 w-10"/>
                </button>
                <ul tw="flex flex-col items-center justify-center h-full">
                    {navLinks.map((link, index) => (
                        <li key={index}>
                            <a href={link.href}>{link.name}</a>
                        </li>
                    ))}
                </ul>
            </aside>
        </menu>
    )
}