import React from "react"
import Layout from "../components/layout/Layout"
import Hero from "../components/sections/Hero"
import About from "../components/sections/About"

export default function Home() {
    return (
            <Layout>
                <Hero />
                <About />
            </Layout>
    )
}
