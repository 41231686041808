import React, {useEffect, useState} from "react";
import tw, {css} from "twin.macro";
import {ClassNames} from '@emotion/react'
import {Transition} from "@headlessui/react";
import Menu from "./Menu";
import useScrollDirection from "../../hooks/useScrollDirection";
import LogoIcon from "../../images/icons/logo2.svg";


const navLinks = [
    {
        name: "about",
        href: "/about"
    },
    {
        name: "projects",
        href: "/#projects"
    },
    {
        name: "contact",
        href: "/#contact"
    },
]

export default function Header() {
    const [isVisible, setIsVisible] = useState(false)
    const [isMounted, setIsMounted] = useState(false);
    const [isScrolledTop, setIsScrolled] = useState(true);
    const scrollDirection = useScrollDirection('down');



    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsMounted(true)
            setIsVisible(true)
        }, 100);

        const handleScroll = () => {
            const offset = window.scrollY;
            setIsScrolled(offset < 50)
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            clearTimeout(timeout);
            window.removeEventListener('scroll', handleScroll);
        }

    }, []);
    {/*<LogoIcon tw="h-12 pt-1 pl-2 text-black bg-bg border-solid border-4 border-black rounded-lg"/>*/}
    const logo = (
        <span id="logo">OG</span>

    )

    return (
        <header tw="bg-transparent fixed flex items-center top-0 w-full px-0 z-10">
            <ClassNames>
                {({css}) => (<Transition
                    wrapperClassName={css(tw`fixed top-0 left-0 right-0 z-50`)}
                    as="div"
                    show={isScrolledTop || scrollDirection === 'up'}
                    enter={css(tw`transition ease duration-300 transform`)}
                    enterFrom={css(tw`opacity-100 -translate-y-20`)}
                    enterTo={css(tw`opacity-100 translate-y-0`)}
                    leave={css(tw`transition ease duration-300 transform`)}
                    leaveFrom={css(tw`opacity-100`)}
                    leaveTo={css(tw`opacity-100 -translate-y-20`)}
                    tw="flex items-center justify-between w-full px-0  z-10 mt-4"
                >
                    <nav tw="relative flex justify-between items-center w-full z-20">
                        <div tw="flex justify-items-center ml-14">
                            <ClassNames>
                                {({css}) => (<Transition
                                    show={isVisible}
                                    enter={css(tw`transform transition-opacity duration-[1500ms]`)}
                                    enterFrom={css(tw`opacity-0`)}
                                    enterTo={css(tw`opacity-100`)}
                                    leave={css(tw`transition-opacity duration-300`)}
                                    leaveFrom={css(tw`opacity-100`)}
                                    leaveTo={css(tw`opacity-0`)}
                                >
                                    {logo}
                                </Transition>)}
                            </ClassNames>
                        </div>
                        <Menu/>
                        <div tw="hidden md:block mr-4">
                            <ul tw="hidden bg-transparent list-none
                            md:flex md:justify-between md:items-center md:w-full md:h-full md:px-0 md:py-0"
                            >
                                {navLinks.map((link, i) => (
                                    <ClassNames>
                                        {({css}) => (<Transition
                                            /*
                                             * todo: Transition has to fire one by one .
                                             */
                                            // appear={true}
                                            show={isVisible}
                                            enter={css(tw`transform transition-opacity duration-[1500ms]`)}
                                            enterFrom={css(tw`opacity-0`)}
                                            enterTo={css(tw`opacity-100`)}
                                            leave={css(tw`transition-opacity duration-300`)}
                                            leaveFrom={css(tw`opacity-100`)}
                                            leaveTo={css(tw`opacity-0`)}
                                        >
                                            <li key={link.name}
                                                tw="text-black text-2xl mr-10 cursor-pointer bg-transparent hover:text-gray-300"
                                            >
                                                <a href={link.href}>{link.name}</a>
                                            </li>
                                        </Transition>)}
                                    </ClassNames>
                                ))}
                            </ul>
                        </div>
                    </nav>
                </Transition>)}
            </ClassNames>
        </header>
    )
}
