import { css } from '@emotion/react'
import '@fontsource/inter/800.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/200.css'
import '@fontsource/crimson-pro/800.css'
import '@fontsource/crimson-pro/700.css'
import '@fontsource/crimson-pro/400.css'
import '@fontsource/crimson-pro/200.css'

const inter = css`
    @font-face {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 200 400 700 800;
        font-display: auto;
        src: local('inter')
    }
`

const crimsonPro = css`
    @font-face {
        font-family: 'Crimson Pro', serif;
        font-style: normal;
        font-weight: 200 400 700 800;
        font-display: auto;
        src: local('crimson-pro')
    }
`

const fonts = css`
    ${inter}
    ${crimsonPro}
`

export default fonts

